
import Vue from 'vue';

export default Vue.extend({
  name: 'App',

  data: () => ({
    //
    showDrawer: false,
    drawerList: [
      {
        title: '气象网址导航',
        icon: 'far fa-compass',
        link: '/',
      },
      {
        title: 'Python编程文档',
        icon: 'fa-laptop-code',
        link: '/python',
      },
      {
        title: 'NMC天气图',
        icon: 'far fa-map',
        link: '/weatherChart',
      },
      {
        title: '广东天气',
        icon: 'far fa-map',
        link: '/gdWeather',
      },
    ],
  }),
});
